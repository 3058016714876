// import React, { useState, useEffect } from "react";
// import { fetchGraphQLData } from "service/graph";
import copy from "assets/img/Burve/copy.png";
import bnb from "assets/img/Chat/bnb-bnb-logo 1.png";

import "./index.scss";
import { useEffect, useState } from "react";
// import { fetchGraphQLData } from "service/graph";
import TradingViewWidget from "components/TradingViewWidget";

function formatNumberWithUnit(value) {
  if (!value && value !== 0) return "-";
  
  if (value >= 1e9) {
    return (value / 1e9).toFixed(2) + "B";
  } else if (value >= 1e6) {
    return (value / 1e6).toFixed(2) + "M";
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(2) + "K";
  } else {
    return value.toFixed(2);
  }
}

export function formatAddress(address) {
  if (!address) {
    return;
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

const Overview = () => {
  // const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  console.log("data", data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        const response = await fetch('https://explorer.pancakeswap.com/api/cached/tokens/v2/bsc/0x0d34b8134aff0e6f1b8a8cce4e144d6dc5a43ff7');
        const data = await response.json();
        console.log("result", data);
        console.log({
          priceUsd: data?.priceUSD,
          marketCap: data?.totalTxCount,
          txCount: data?.tvlUSD,
          tradeVolumes: data?.totalVolumeUSD,
        });
        setData(data);
      } catch (err) {
        console.log(err.message);
      } finally {
        // setLoading(false);
      }
    };

    // 立即执行一次
    fetchData();

    // 设置10min轮询
    const intervalId = setInterval(fetchData, 600000);

    // 清理函数
    return () => clearInterval(intervalId);
  }, []);

  const handleCopy = () => {
    const address = "0x0d34B8134AFf0e6f1b8a8ccE4e144d6dc5A43Ff7"; // or data?.tokenEntity?.addr
    navigator.clipboard
      .writeText(address)
      .then(() => {
        // Optional: Add some visual feedback that the copy was successful
        // alert("Address copied to clipboard!");
        // Or use a more sophisticated notification system if available
      })
      .catch((err) => {
        console.error("Failed to copy address: ", err);
      });
  };

  return (
    <>
      <div className="overview-title">FUGU Token Overview</div>
      <div className="overview-des">
        Buy and stake during the presale before prices skyrocket!
      </div>
      <div style={{ height: "400px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <TradingViewWidget />
      </div>
      <div className="overview-container">
        <div className="overview-content">
          {/* 左侧图表 */}
          {/* <div className="chart-container">
            <iframe
              src="https://dev-chart.pages.dev/?address=0xe9ce0984f9d683210b45159ad3616e210f749091&chainId=11155111"
              style={{
                width: "100%",
                height: "400px",
                border: "none",
                borderRadius: "10px",
              }}
              title="Fugu Chart"
            />
          </div> */}

          {/* 右侧动态数据 */}
          <div className="data-container">
            <div className="data-item">
              <div className="label">Price of FUGU</div>
              <div className="value">
                {/* {"-"} */}
                $
                {Number(data?.priceUSD)?.toFixed(4) || "-"}
              </div>
            </div>
            <div className="data-item">
              <div className="data-usdt">
                <div className="label">Liquidity</div>
                {/* <div className="usdt"></div> */}
              </div>

              <div className="value">
                {/* {"-"} */}
                $
                {formatNumberWithUnit(Number(data?.tvlUSD)) || "-"}
              </div>
            </div>
            <div className="data-item">
              <div className="label">Total volume</div>
              {/* <div className="value">{"-"}</div> */}
              <div className="value">${formatNumberWithUnit(Number(data?.totalVolumeUSD)) || "-"}</div>
            </div>
            <div className="data-item">
              <div className="label">Transactions</div>
              <div className="value">
                {/* {"-"} */}
                {data?.totalTxCount || "-"}
              </div>
            </div>
            <div className="data-item">
              <div className="label">FUGU Token Contracts</div>
            </div>
            <div className="data-item">
              <div className="wallet">
                <div className="wallet-icon">
                  <img src={bnb} alt=""></img>
                </div>
                <div className="wallet-address">
                  <p>BNB SmartChain（BSC）</p>
                  <p>
                    0x0d34B8134AFf0e6f
                    <br />
                    1b8a8ccE4e144d6dc5A43Ff7
                  </p>
                  {/* <p>{formatAddress(data?.tokenEntity?.addr)}</p> */}
                </div>
                {/* cooy function */}
                <div className="wallet-copy" onClick={handleCopy}>
                  <img
                    src={copy}
                    alt="copy address"
                    style={{ cursor: "pointer" }}
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
