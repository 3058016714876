import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar"; // 引入 MUI 的 Avatar 组件
import logo from "assets/img/Chat/logo.png";
import Vector from "assets/img/bg/Vector.png";
import Vectorzise from "assets/img/bg/Vectorzise.png";
import styles from "./chat.module.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const formatForMarkdown = (text) => text.replace(/\\n/g, "  \n");

const chatList = [
  { key: "Launch Meme Coin", value: "How to launch a meme coin step by step?" },
  {
    key: "Freedom Foundation",
    value: "What is the Freedom Foundation's mission and role?",
  },
  { key: "Buy Meme Guide", value: "Guide me on how to buy meme coins safely." },
  {
    key: "Roadmap Insights",
    value: "What are the key elements of the project roadmap?",
  },
  { key: "Fugu Staking", value: "Explain Fugu Staking and its benefits." },
  { key: "Bonding Curve 101", value: "What is a bonding curve in crypto?" },
  {
    key: "Freedom of Speech Matters",
    value: "Why does freedom of speech matter today?",
  },
];

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [clickedPresets, setClickedPresets] = useState([]); // 记录已点击的预置按钮
  const [streamingMessage, setStreamingMessage] = useState(""); // 当前流式消息
  const [loading, setLoading] = useState(false); // 是否显示 loading 动画

  // const messageListRef = useRef(null); // 引用消息列表 DOM
  const messageListRef = useRef(null); // 引用消息列表 DOM
  const controllerRef = useRef(null);

  // 自动滚动到聊天框底部
  // useEffect(() => {
  //   if (messageListRef.current) {
  //     messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  //   }
  // }, [messages, streamingMessage, loading]);
  const handleSend = async (message) => {
    if (!message.trim()) return;

    const newMessage = {
      text: message,
      type: "sent",
    };

    setMessages((prev) => [...prev, newMessage]);
    setInput("");
    setStreamingMessage(""); // 清空当前流式消息
    setLoading(true); // 显示 loading 动画

    const messageHistory = messages.map(msg => ({
      role: msg.type === "sent" ? "user" : "assistant",
      content: msg.text
    }));

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const response = await fetch(
        "https://api.fugu.club/api/v1/chatbot/ama",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            query: message,
            messages: messageHistory
          }),
          signal: controller.signal,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to connect to the server.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let isDone = false; // 标记流式结束
      let updatedText = "";

      while (!isDone) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n"); // 按行分割返回的数据

        // 使用局部变量来安全地更新 totalText

        for (const line of lines) {
          if (line.startsWith("data:")) {
            const data = line.replace("data:", "").trim();

            // 检查是否为 [DONE] 标记
            if (data === "[DONE]") {
              isDone = true; // 设置结束标志
              break;
            }
            updatedText += data?.replaceAll('"', "");
            setStreamingMessage(updatedText);
            // 直接更新流式消息
          }
        }
      }

      // 将完整消息添加到消息列表
      if (updatedText.trim()) {
        setMessages((prev) => [
          ...prev,
          { text: updatedText, type: "received" },
        ]);
      }

      setStreamingMessage(""); // 清空流式消息
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          text: "Error receiving response. Please try again.",
          type: "received",
        },
      ]);
    } finally {
      setLoading(false);
      controllerRef.current = null; // 清除 AbortController
    }
  };

  const handlePresetClick = (preset) => {
    if (!clickedPresets.includes(preset?.key)) {
      setClickedPresets((prev) => [...prev, preset?.key]); // 记录点击的预置按钮
    }
    handleSend(preset?.value); // 发送预置消息
    handleFocus();
  };

  const handleSubmit = () => {
    if (input.trim() === "") return;
    handleSend(input.trim());
    setInput("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  const handleFocus = () => {
    // const scrollContainer = document.querySelector(".chat-scroll"); // 获取滚动容器
    // if (!scrollContainer) {
    //   return
    // }
    // window.scrollTo({
    //   top: Number(scrollContainer.getBoundingClientRect().height) + 200,
    //   behavior: "smooth", // 平滑滚动
    // });
    const targetElement = document.querySelector("#chat-page");
    if (!targetElement) {
      return;
    }
    targetElement.scrollIntoView({
      behavior: "smooth", // 平滑滚动
      block: "start", // 滚动到目标的顶部
    });
  };

  // 自动滚动到聊天框底部;
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, streamingMessage, loading]);

  return (
    <div className={styles.chatPage}>
      <div className={styles.bgLeft}>
        <img src={Vector} alt=""></img>
      </div>
      {/* 标题和 Preset 按钮 */}
      <div className={styles.header}>
        <div className={styles.title}>
          <img src={logo} alt="Ask Me Anything" className={styles.icon} />
          <h2>Ask Me Anything</h2>
        </div>
        <h4>Ask Fugubot Bot a question</h4>
        <div className={styles.presets}>
          {chatList.map((preset, index) => (
            <button
              key={index}
              className={`${styles.presetButton} ${clickedPresets.includes(preset?.key) ? styles.clickedPreset : ""
                }`}
              onClick={() => handlePresetClick(preset)}
            >
              {preset?.key} ↗
            </button>
          ))}
        </div>
      </div>
      {/* 聊天区域 */}
      <div className={styles.chatContainer} id="chat-input">
        <div className={styles.bgRight}>
          <img src={Vectorzise} alt="" />
        </div>

        {/* 包裹层，控制聊天内容和默认消息 */}
        <>
          {messages.length === 0 && !streamingMessage && !loading ? (
            <div className={styles.defaultMessage}>
              <p>It will do its best to answer your questions.</p>
            </div>
          ) : (
            <div
              className={styles.messageList}
              ref={messageListRef} // 绑定消息列表 DOM
            >
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`${styles.messageRow} ${msg.type === "sent" ? styles.sentRow : styles.receivedRow
                    }`}
                >
                  {msg.type === "received" ? (
                    <Avatar
                      className={styles.avatar}
                      src="/path/to/system-avatar.png"
                      alt="System"
                    />
                  ) : null}
                  <p
                    className={`${styles.message} ${msg.type === "sent" ? styles.sent : styles.received
                      }`}
                  >
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {formatForMarkdown(msg.text)}
                    </ReactMarkdown>
                  </p>
                  {msg.type === "sent" ? (
                    <Avatar
                      className={styles.avatar}
                      src="/path/to/user-avatar.png"
                      alt="User"
                    />
                  ) : null}
                </div>
              ))}

              {/* 显示流式消息 */}
              {streamingMessage && (
                <div className={`${styles.messageRow} ${styles.receivedRow}`}>
                  <Avatar
                    className={styles.avatar}
                    src="/path/to/system-avatar.png"
                    alt="System"
                  />
                  <p className={`${styles.message} ${styles.received}`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {formatForMarkdown(streamingMessage)}
                    </ReactMarkdown>
                  </p>
                </div>
              )}

              {/* 显示加载动画 */}
              {loading && !streamingMessage && (
                <div className={`${styles.messageRow} ${styles.receivedRow}`}>
                  <Avatar
                    className={styles.avatar}
                    src="/path/to/system-avatar.png"
                    alt="System"
                  />
                  <div className={styles.loading}>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </>

        {/* 输入框 */}
        <div className={styles.inputContainer}>
          <input
            type="text"
            placeholder="Please enter ..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress} // 监听回车键
            onFocus={handleFocus}
            className={styles.input}
          />
          <button onClick={handleSubmit} className={styles.sendButton}>
            ➤
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
