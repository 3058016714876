import logo from "assets/img/header/logo.svg";
import Twitter from "assets/img/footer/Link - Twitter.svg";
import Telegram from "assets/img/footer/Link - Telegram.svg";
import leftFish from "assets/img/bg/leftFish.gif";
import rightFish from "assets/img/bg/rightFish.gif";
import "./index.scss";

const Footer = () => {
  const handleX = () => {
    window.open("https://twitter.com/fugutoxic01", "_blank");
  };
  const handletel = () => {
    window.open("https://t.me/FuguToxicBot", "_blank");
  };
  return (
    <footer className="footer">
      <div className="bg_left_fish">
        <img src={leftFish} alt="" className="left_fish"></img>
        <div className="video-overlay overlay"></div>
        <div className="video-overlay_top overlay"></div>
        <div className="video-overlay_right overlay"></div>
      </div>
      <div className="bg_right_fish">
        <img src={rightFish} alt="" className="right_fish"></img>
        <div className="video-overlay overlay"></div>
        <div className="video-overlay_top overlay"></div>
        <div className="video-overlay_left overlay"></div>
        <div className="video-overlay_right overlay"></div>
      </div>
      <div className="footer_box">
        <div className="footer_content">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer-links">
            <img src={Twitter} alt="Logo" onClick={handleX} />
            {/* <img src={Discord} alt="Logo" /> */}
            {/* <img src={GitHub} alt="Logo" /> */}
            <img src={Telegram} alt="Logo" onClick={handletel} />
          </div>
        </div>

        <div className="footer-bar"></div>
        <div className="footer-bottom"></div>
      </div>
    </footer>
  );
};

export default Footer;
