import { useState, useEffect } from "react";
import Header from "components/Header";
import PhoneHeader from "components/PhoneHeader";
import fuguRight from "assets/img/banner/fuguRight.png";
import bannerTitle from "assets/img/banner/bannerTitle.png";
import EFF from "assets/img/banner/EFF.png";
import "./index.scss";

const HomeContent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const CurrentHeader = isMobile ? PhoneHeader : Header;

  const handleNite = () => {
    window.open(
      "https://pancakeswap.finance/info/pairs/0x5bB0fF5adE966396a1cc9e089b48a604e1923f30",
      "_blank"
    );
  };

  const handleMEME = () => {
    window.open("https://fugu.meme", "_blank");
  };

  return (
    <div className="home_box">
      <CurrentHeader />
      <div className="home_content_box">
        <div className="home_content">
          <div className="content_left">
            <div className="home_title">
              <div> Fugu toxic</div>
              <img src={bannerTitle} alt=""></img>
              <img src={EFF} alt=""></img>
            </div>
            <div className="text_box">
              <div className="home_des">
                A portion of the transaction fee will be automatically donated
                to Electronic Frontier Foundation
              </div>
            </div>

            <div className="sec_des" style={{ fontWeight: "bold" }}>
              ONI is the first to issue memes on Fugu using it as a tradingpair.
              The Fugu meme <span style={{ color: "#fef75c" }}>launchpad</span> will be released in three weeks for
              <span style={{ color: "#fef75c" }}> anyone</span> to create their own memes.
            </div>
            {!isMobile && (
              <div className="home_btn">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", height: '82px', width: '248px', marginRight: "1.75rem", backgroundImage: "url(/meme.png)", backgroundSize: "100% 100%", backgroundPosition: "center -15px", backgroundRepeat: "no-repeat" }} onClick={handleMEME}>
                  <span style={{ transform: "translateX(30px)" }}>FUGU.MEME</span>
                </div>
                <div className="nite_btn" onClick={handleNite}>
                  BUY FUGU
                </div>
              </div>
            )}
          </div>
          <div className="content_right">
            <img src={fuguRight} alt=""></img>
          </div>
        </div>
        {isMobile && (
          <div className="home_btn">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", height: '82px', width: '248px', backgroundImage: "url(/meme.png)", backgroundSize: "100% 100%", backgroundPosition: "center -15px", backgroundRepeat: "no-repeat" }} onClick={handleMEME}>
              <span style={{ transform: "translateX(30px)" }}>FUGU.MEME</span>
            </div>
            <div className="nite_btn" style={{ marginTop: "1rem", width: "175px" }} onClick={handleNite}>
              BUY FUGU
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeContent;
